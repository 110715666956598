import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../assets/logo.svg";
import Arrow from "../../assets/arrow-left.svg";

import Phone from "../../assets/phone.svg";
import { useData } from "../../dataProvider";

export default function Header() {
  const [contactVisible, setContactVisible] = useState(false);
  const { setLang, lang } = useData();
  const location = useLocation();
  return (
    <div className="Header">
      <Link to="/">
        {location.pathname === "/" ? (
          <img src={Logo} alt="Logo" className="logo" />
        ) : (
          <img src={Arrow} alt="Zurück" className="logo" />
        )}
      </Link>
      <div className="title">
        {lang == "de" ? "Gästeinformationen" : "Guest information"}
      </div>
      <div style={{ display: "flex" }}>
        <div className="language">
          <div
            onClick={() => setLang("de")}
            className={`lang ${lang === "de" && "active"}`}
          >
            {" "}
            DE
          </div>
          <div
            onClick={() => setLang("en")}
            className={`lang ${lang === "en" && "active"}`}
          >
            EN
          </div>
        </div>
        <div className="contact">
          <img
            src={Phone}
            alt="Phone"
            onClick={() => setContactVisible(!contactVisible)}
            className={`phone ${contactVisible && "active"}`}
          />
          {contactVisible && (
            <div class="contact-wrapper">
              <h2>Kontakt</h2>
              <div class="text">
                <p>
                  <br />
                  E-Mail:{" "}
                  <a
                    title="mailto:info@batschari-palais.de"
                    href="mailto:info@batschari-palais.de"
                  >
                    info@batschari-palais.de&nbsp;
                  </a>
                  <br />
                  Telefon: 07221/973990
                  <a
                    title="mailto:infolbatschari-palais.de"
                    href="mailto:infolbatschari-palais.de"
                  >
                    <br />
                  </a>
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
