import React from "react";
import { Link } from "react-router-dom";
import { useData } from "../../dataProvider";

export default function Dashboard({ url, image, title, external, en }) {
  const { lang } = useData();
  if (external) {
    return (
      <a
        target="_blank"
        rel="noopener noreferrer"
        className="dashboard-item"
        style={{ backgroundImage: `url(${image})` }}
        href={url}
      >
        <div className="inner">{title}</div>
      </a>
    );
  }
  return (
    <Link
      className="dashboard-item"
      style={{ backgroundImage: `url(${image})` }}
      to={url}
    >
      <div className="inner">{lang == "de" ? title : en}</div>
    </Link>
  );
}
