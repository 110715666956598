import React, { useState, useEffect } from "react";
import DEData from "./de.json";
import ENData from "./en.json";
import { useData } from "../../dataProvider";

import "./style.scss";

export default function AZ() {
  const { lang } = useData();
  const alpha = Array.from(Array(26)).map((e, i) => i + 65);
  const alphabet = alpha.map((x) => String.fromCharCode(x));
  const [data, setData] = useState(DEData);

  const [items, setItems] = useState(data.sort((a, b) => sort(a, b)));
  const [filter, setFilter] = useState(null);

  function sort(a, b) {
    if (a.headline < b.headline) {
      return -1;
    }
    if (a.headline > b.headline) {
      return 1;
    }
    return 0;
  }

  useEffect(() => {
    let filteredItems = data.sort((a, b) => sort(a, b));
    if (filter) {
      filteredItems = filteredItems.filter(
        (a) => a.headline?.toUpperCase().charAt(0) === filter
      );
    }
    setItems(filteredItems);
  }, [filter, data]);

  useEffect(() => {
    if (lang == "en") setData(ENData.sort((a, b) => sort(a, b)));
    if (lang == "de") setData(DEData.sort((a, b) => sort(a, b)));
  }, [lang]);

  return (
    <div className="AZ">
      <h1>A-Z</h1>
      <div className="glossary-index">
        <div
          className={`all letter ${!filter && "active"}`}
          onClick={() => setFilter(null)}
        >
          {lang === "de" ? "Alle" : "All"}
          <div className="count">
            {"("}
            {data.length}
            {")"}
          </div>
        </div>
        {alphabet &&
          alphabet.map((letter, idx) => {
            return (
              <div
                className={`letter ${filter === letter && "active"}`}
                onClick={() => setFilter(letter)}
              >
                {letter}{" "}
              </div>
            );
          })}
      </div>
      <div className="glossary-grid">
        {items &&
          items.map((item, idx) => {
            if (!item.headline) return "";
            return (
              <div className="glossary-item">
                <div className="title">{item.headline}</div>
                <div
                  className="text"
                  dangerouslySetInnerHTML={{ __html: item.beschreibung }}
                ></div>
              </div>
            );
          })}
      </div>{" "}
    </div>
  );
}
