import React from "react";
import "./style.scss";
import Item from "./Item";
import Kachel1 from "../../assets/kachel1.jpeg";
import Kachel2 from "../../assets/kachel2.jpeg";
import Kachel3 from "../../assets/kachel3.jpeg";
import Kachel4 from "../../assets/kachel4.jpeg";

import Weather from "./Weather";

export default function Dashboard() {
  return (
    <div className="Dashboard">
      <h1>Herzlich willkommen im Batschari Palais</h1>
      <div className="dashboard-grid">
        <Item url="/az" title="A-Z" image={Kachel1} en="Glossary" />
        <Item
          url="/places"
          title="Sehenswürdigkeiten"
          en="Places of Interest"
          image={Kachel3}
        />
        <Item
          url="/activity"
          title="Aktivitäten"
          en="Activity"
          image={Kachel4}
        />
        <Item
          url="https://www.batschari-palais.de"
          title="Website"
          external
          image={Kachel2}
        />
        <Weather />
      </div>
    </div>
  );
}
