import React, { useState, useContext } from "react";

export const DataContext = React.createContext();
export const useData = () => useContext(DataContext);
export const DataProvider = ({ children, ...initOptions }) => {
  const [lang, setLang] = useState("de");

  const exports = {
    lang,
    setLang,
  };
  return (
    <DataContext.Provider value={exports}>{children}</DataContext.Provider>
  );
};
