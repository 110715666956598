import ReactWeather, { useOpenWeather } from "react-open-weather";
import { useData } from "../../dataProvider";

const Weather = () => {
  const { lang } = useData();
  const { data, isLoading, errorMessage } = useOpenWeather({
    key: "7ad07aac9b0943040a4abdd2c23dfc4e",
    lat: "48.76775902111108",
    lon: "8.238146375981259",
    lang: lang,
    unit: "metric", // values are (metric, standard, imperial)
  });
  return (
    <div className="weather">
      <ReactWeather
        isLoading={isLoading}
        errorMessage={errorMessage}
        data={data}
        lang={lang}
        locationLabel="Baden-Baden"
        unitsLabels={{ temperature: "C", windSpeed: "Km/h" }}
        showForecast={false}
      />
    </div>
  );
};
export default Weather;
